import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'

import PageTabSwitch from '../components/PageTabSwitch/PageTabSwitch'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import PaginationNavigation from '../components/PaginationNavigation/PaginationNavigation'
import FeedCardHolderBig from '../components/FeedCardHolderBig/FeedCardHolderBig'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'

export default function Faq(props) {
	const allWordpressWpFaq = props.data.allWordpressWpFaq.edges

	const faq = allWordpressWpFaq.map((event) => {
		return {
			titles: [event.node.acf.question, undefined],
			textContent: [undefined, ReactHtmlParser(event.node.acf.answer)],
		}
	})

	return (
		<SecondaryLayout>
			<Helmet>
				<meta
					name="description"
					content={
						'Bekijk de meest gestelde vragen van In Balans Alkmaar.'
					}
				/>
			</Helmet>
			<PagePadding>
				<Column>
					<Title
						heading={1}
						text={'Veelgestelde vragen'}
						wave={true}
					/>
					<PageTabSwitch
						links={[
							{ name: 'Kalender', to: '/kalender' },
							{ name: 'Nieuws', to: '/nieuws' },
							{ name: 'FAQ', to: props.uri },
						]}
					/>
					<Column>
						<FeedCardHolderBig cards={faq} />
					</Column>
				</Column>
				<PaginationNavigation pageContext={props.pageContext} />
			</PagePadding>
		</SecondaryLayout>
	)
}

export const pageQuery = graphql`
	query($skip: Int!, $limit: Int!) {
		allWordpressWpFaq(
			sort: { fields: date, order: DESC }
			skip: $skip
			limit: $limit
		) {
			edges {
				node {
					acf {
						question
						answer
					}
				}
			}
		}
	}
`
